<!--
* 创建人：罗兴
* 日 期：
* 描 述：
-->
<!--view 模板-->
<template>
  <div class="container">
    <el-scrollbar
      :style="{ height: scrollbarheight + 'px' }"
      class="myscrollbar"
    >
      <div>
        <div class="pd5 tcenter">
          <el-button type="primary" icon="el-icon-printer" v-print="printObj"
            >开始打印</el-button
          >
        </div>
        <div class="con" id="printMe">
          <table cellpadding="0" cellspacing="0" class="table1">
            <tr>
              <td colspan="2">姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名</td>
              <td style="width: 110px" colspan="2" rowspan="4"></td>
            </tr>
            <tr>
              <td colspan="2">李晶</td>
            </tr>
            <tr>
              <td width="45">性别</td>
              <td>女</td>
            </tr>
            <tr>
              <td>出生<br />年月</td>
              <td>2001/3</td>
            </tr>
            <tr>
              <td>学号</td>
              <td colspan="3" style="text-align: left; padding-left: 10px">
                2015051111
              </td>
            </tr>
            <tr>
              <td>入学<br />时间</td>
              <td style="text-align: left; padding-left: 10px">2020年9月</td>
              <td width="50">民族</td>
              <td width="60">汉族</td>
            </tr>
            <tr>
              <td>学院</td>
              <td colspan="3" style="text-align: left; padding-left: 10px">
                外语学院
              </td>
            </tr>
            <tr>
              <td>专业</td>
              <td colspan="3" style="text-align: left; padding-left: 10px">
                酒店管理
              </td>
            </tr>
            <tr>
              <td>班级</td>
              <td colspan="3" style="text-align: left; padding-left: 10px">
                20酒店（本）
              </td>
            </tr>
            <tr>
              <td>发证<br />时间</td>
              <td colspan="3" style="text-align: left; padding-left: 10px">
                2021年9月
              </td>
            </tr>
          </table>
          <table cellpadding="0" cellspacing="0" class="table2">
            <tr>
              <td width="115" height="25">第一学年第一学期</td>
              <td width="115" height="25">第一学年第二学期</td>
            </tr>
            <tr>
              <td height="32"></td>
              <td height="32"></td>
            </tr>
            <tr>
              <td width="115" height="25">第二学年第一学期</td>
              <td width="115" height="25">第二学年第二学期</td>
            </tr>
            <tr>
              <td height="32"></td>
              <td height="32"></td>
            </tr>
            <tr>
              <td width="115" height="25">第三学年第一学期</td>
              <td width="115" height="25">第三学年第二学期</td>
            </tr>
            <tr>
              <td height="32"></td>
              <td height="32"></td>
            </tr>
            <tr>
              <td width="115" height="25">第四学年第一学期</td>
              <td width="115" height="25">第四学年第二学期</td>
            </tr>
            <tr>
              <td height="32"></td>
              <td height="32"></td>
            </tr>
            <tr>
              <td width="115" height="25">第五学年第一学期</td>
              <td width="115" height="25">第五学年第二学期</td>
            </tr>
            <tr>
              <td height="31"></td>
              <td height="31"></td>
            </tr>
            <tr>
              <td width="115" height="25">第六学年第一学期</td>
              <td width="115" height="25">第六学年第二学期</td>
            </tr>
            <tr>
              <td height="31"></td>
              <td height="31"></td>
            </tr>
          </table>
          <table cellpadding="0" cellspacing="0" class="table3">
            <tr>
              <td colspan="3" style="font-size: 18px; height: 45px">
                火车票减价优待凭证
              </td>
            </tr>
            <tr>
              <td width="80">学校地址</td>
              <td colspan="2" style="text-align: left; padding-left: 10px">
                四川省&nbsp;&nbsp;成都市
              </td>
            </tr>
            <tr>
              <td>家庭住址</td>
              <td colspan="2" style="text-align: left; padding-left: 10px">
                四川省&nbsp;&nbsp;成都市
              </td>
            </tr>
            <tr>
              <td>乘车区间</td>
              <td colspan="2" style="text-align: left; padding-left: 10px">
                成都&nbsp;&nbsp;至&nbsp;&nbsp;眉山
              </td>
            </tr>
            <tr>
              <td colspan="3" style="font-size: 18px; height: 34px">
                购&nbsp;&nbsp;票&nbsp;&nbsp;记&nbsp;&nbsp;录
              </td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td width="115"></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td></td>
            </tr>
          </table>
          <table cellpadding="0" cellspacing="0" class="table4">
            <tr>
              <td style="font-size: 18px; height: 45px">火车票减价优待凭证</td>
            </tr>
            <tr>
              <td style="height: 300px">贴卡处</td>
            </tr>
          </table>

          <table cellpadding="0" cellspacing="0" class="table1">
            <tr>
              <td width="130" colspan="2">
                姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名
              </td>
              <td width="100" colspan="2" rowspan="4"></td>
            </tr>
            <tr>
              <td colspan="2">李晶</td>
            </tr>
            <tr>
              <td width="45">性别</td>
              <td>女</td>
            </tr>
            <tr>
              <td>出生<br />年月</td>
              <td>2001/3</td>
            </tr>
            <tr>
              <td>学号</td>
              <td colspan="3">2015051111</td>
            </tr>
            <tr>
              <td>入学<br />时间</td>
              <td>2020年9月</td>
              <td width="50">民族</td>
              <td width="60">汉族</td>
            </tr>
            <tr>
              <td>学院</td>
              <td colspan="3">外语学院</td>
            </tr>
            <tr>
              <td>专业</td>
              <td colspan="3">酒店管理</td>
            </tr>
            <tr>
              <td>班级</td>
              <td colspan="3">20酒店（本）</td>
            </tr>
            <tr>
              <td>发证<br />时间</td>
              <td colspan="3">2021年9月</td>
            </tr>
          </table>
          <table cellpadding="0" cellspacing="0" class="table2">
            <tr>
              <td width="115" height="25">第一学年第一学期</td>
              <td width="115" height="25">第一学年第二学期</td>
            </tr>
            <tr>
              <td height="32"></td>
              <td height="32"></td>
            </tr>
            <tr>
              <td width="115" height="25">第二学年第一学期</td>
              <td width="115" height="25">第二学年第二学期</td>
            </tr>
            <tr>
              <td height="32"></td>
              <td height="32"></td>
            </tr>
            <tr>
              <td width="115" height="25">第三学年第一学期</td>
              <td width="115" height="25">第三学年第二学期</td>
            </tr>
            <tr>
              <td height="32"></td>
              <td height="32"></td>
            </tr>
            <tr>
              <td width="115" height="25">第四学年第一学期</td>
              <td width="115" height="25">第四学年第二学期</td>
            </tr>
            <tr>
              <td height="32"></td>
              <td height="32"></td>
            </tr>
            <tr>
              <td width="115" height="25">第五学年第一学期</td>
              <td width="115" height="25">第五学年第二学期</td>
            </tr>
            <tr>
              <td height="31"></td>
              <td height="31"></td>
            </tr>
            <tr>
              <td width="115" height="25">第六学年第一学期</td>
              <td width="115" height="25">第六学年第二学期</td>
            </tr>
            <tr>
              <td height="31"></td>
              <td height="31"></td>
            </tr>
          </table>
          <table cellpadding="0" cellspacing="0" class="table3">
            <tr>
              <td colspan="3" style="font-size: 18px; height: 45px">
                火车票减价优待凭证
              </td>
            </tr>
            <tr>
              <td width="80">学校地址</td>
              <td colspan="2">四川省&nbsp;&nbsp;成都市</td>
            </tr>
            <tr>
              <td>家庭住址</td>
              <td colspan="2">四川省&nbsp;&nbsp;成都市</td>
            </tr>
            <tr>
              <td>乘车区间</td>
              <td colspan="2">成都&nbsp;&nbsp;至&nbsp;&nbsp;眉山</td>
            </tr>
            <tr>
              <td colspan="3" style="font-size: 18px; height: 34px">
                购&nbsp;&nbsp;票&nbsp;&nbsp;记&nbsp;&nbsp;录
              </td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td width="115"></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td></td>
            </tr>
          </table>
          <table cellpadding="0" cellspacing="0" class="table4">
            <tr>
              <td style="font-size: 18px; height: 45px">火车票减价优待凭证</td>
            </tr>
            <tr>
              <td style="height: 300px">贴卡处</td>
            </tr>
          </table>
        </div>
      </div>
      <br />
    </el-scrollbar>
  </div>
</template>
<!-- javascript脚本-->
<script>
export default {
  data() {
    return {
      scrollbarheight: document.documentElement.clientHeight - 100,
      printObj: {
        id: 'printMe', // 这里是要打印元素的ID
        popTitle: '', // 打印的标题
        extraHead: '', // 打印头部文字
      },
      ids: [],
    }
  },
  created() {
    this.ids = this.$route.query.ids
  },
  methods: {},
  mounted() {
    window.onresize = () => {
      return (() => {
        this.scrollbarheight = document.documentElement.clientHeight - 100
      })()
    }
  },
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.con {
  margin: auto;
  text-align: center;
  width: 1200px;
  clear: both;
  display: flex;
  margin-bottom: 10px;
  flex-wrap: wrap;
  font-family: '宋体';
}
table {
  border-collapse: collapse;
}

table .bold {
  font-weight: bold;
}

.table1 td {
  border: 1px solid #000;
  text-align: center;
  height: 34px;
  font-size: 14px;
}

.table1 .row {
  width: 100%;
  padding: 10px 10px;
  text-align: left;
}

.table1 .row .cell {
  min-width: 35%;
  float: left;
  padding: 10px 5px;
}

.table1 {
  margin-top: 40px;
  margin-left: 100px;
  height: 376px;
  width: 254px;
}
.table2 {
  margin-top: 40px;
  margin-left: 25px;
  height: 376px;
  width: 254px;
  box-sizing: border-box;
}
.table2 td {
  border: 1px solid #000;
  text-align: center;
  font-size: 14px;
}

.table2 .row {
  width: 100%;
  padding: 10px 10px;
  text-align: left;
}

.table2 .row .cell {
  min-width: 35%;
  float: left;
  padding: 10px 5px;
}

.table3 {
  margin-top: 40px;
  margin-left: 25px;
  height: 376px;
  width: 254px;
}
.table3 td {
  border: 1px solid #000;
  text-align: center;
  font-size: 14px;
  height: 33px;
}

.table3 .row {
  width: 100%;
  padding: 10px 10px;
  text-align: left;
}

.table3 .row .cell {
  min-width: 35%;
  float: left;
  padding: 10px 5px;
}
.table4 {
  margin-top: 40px;
  margin-left: 25px;
  height: 376px;
  width: 254px;
}
.table4 td {
  border: 1px solid #000;
  text-align: center;
  font-size: 14px;
  height: 33px;
}

.table4 .row {
  width: 100%;
  padding: 10px 10px;
  text-align: left;
}

.table4 .row .cell {
  min-width: 35%;
  float: left;
  padding: 10px 5px;
}
</style>>
